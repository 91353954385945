import axios from 'axios'
// import JSON_BIG from 'json-bigint'
import QS from 'qs'

// axios.defaults.baseURL = 'https://capi.supercells.pro/'

axios.defaults.baseURL = '/';

// set timeout
axios.defaults.timeout = 10000

// set post header
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.transformResponse = data => {
//   try {
//     return data
//   } catch (err) {
//     // return JSON.parse(data)
//     return data
//   }
// }

// set response interception
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response)
    }

    return Promise.reject(response)
  },
  error => Promise.reject(error.response),
)

export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params))
      .then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err.data)
      })
  })
}

export function post_raw (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err.data)
      })
  })
}